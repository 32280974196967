// import config from 'config';
import axios from "axios";
import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  authHeader,
} from "../_helpers";

const path = "/timereport/journal/";

export const journalService = {
  getAll,
  get,
  post,
  put,
};

async function getAll(customer_id, employee_id, uuid, start, end, tags = false) {
  const params = {
    customer_id: customer_id,
    employee_id: employee_id,
    uuid: uuid,
    start: start,
    end: end,
    tags: tags,
  };
  return getApiRequest(path, params, authHeader(), "json", 2);
}

function get(id) {
  return getApiRequest(path + id + "/");
}

function post(data) {
  return postApiRequest(path, data, {});
}

function put(id, data) {
  return putApiRequest(path + id + "/", data, {});
}

function remove(id) {
  return deleteApiRequest(path + id + "/");
}
